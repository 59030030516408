import React from "react";
import { graphql, Link } from "gatsby";
import tw, { styled } from "twin.macro";
import "styled-components/macro";
import _ from "lodash";
import moment from "moment-timezone";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Hero from "@shared/components/Hero";
import { TZ } from "@shared/constants";
import { formatImagePositionStr } from "@shared/utils";
import LogoImgBackup from "@shared/components/LogoImgBackup";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { MAIN_COLOR, BACKUP_LOGO_URL } from "../config.js";
import { useSiteFilter } from "../context/SiteFilterContext.js";

const InnerSection = styled.article`
  ${tw`
    w-full
    mx-auto my-5 sm:my-8
    px-5 sm:px-8
  `}
`;

const BaseLink = styled(Link)`
  ${tw`inline-block max-w-max`}
`;

const HeaderLink = styled(BaseLink)`
  ${tw`font-semibold hover:brightness-75`}
`;

const ColoredLink = styled(BaseLink)`
  ${tw`hover:text-[#EC1F27]`}
`;

const Body = styled.div`
  ${tw`prose max-w-full`}

  iframe {
    ${tw`w-full`}
  }
`;

const OtherPosts = styled.div`
  ${tw`pt-5 sm:pt-10`}
`;

const OpPostsContainer = styled.div`
  ${tw`grid gap-4 grid-cols-3`}
`;

const OpArticle = styled.article`
  ${tw`
    flex flex-col
    h-full
  `}
`;

const OpHeaderLink = styled(ColoredLink)`
  ${tw`my-2 text-base sm:text-lg`}
`;

const PostInfo = styled.div`
  ${tw`mb-8 w-full text-base`}
`;

const NewsPost = ({ data, location }) => {
  const {
    markdownRemark: {
      html,
      excerpt,
      frontmatter: { seo, title, date, author, categories, hero },
    },
    otherPosts: { edges: otherPosts },
  } = data;
  const breakpoints = useBreakpoint();
  const isMobile = Object.values(breakpoints).every((value) => value === false);
  const { pageImage, pageImagePosition, useMobileImage, mobileImage } =
    hero || {};

  const getUrl = (type, typeVal) => {
    let retUrl = "/news";

    if (typeVal && type) {
      retUrl += `/${type}/` + typeVal.replace(/\s+/g, "-").toLowerCase();
    }

    return retUrl;
  };

  const heroImgPos = formatImagePositionStr(pageImagePosition);
  return (
    <Layout
      seoTitle={title}
      seoDescription={seo?.description || excerpt}
      location={location}
      useSeoForTitle
    >
      {pageImage ? (
        isMobile && useMobileImage && mobileImage ? (
          <Hero img={mobileImage} color={MAIN_COLOR} />
        ) : (
          <Hero img={pageImage} imgPosition={heroImgPos} color={MAIN_COLOR} />
        )
      ) : (
        <LogoImgBackup
          aspectRatio={30}
          color={MAIN_COLOR}
          src={BACKUP_LOGO_URL}
        />
      )}
      <InnerSection>
        <PostInfo>
          <div tw="text-gray-400">
            {moment(date).tz(TZ).format("dddd, MMMM D YYYY")} / Published in{" "}
            {(categories || []).map((category, idx) => (
              <React.Fragment key={idx}>
                <BaseLink to={getUrl("category", category)} tw="font-semibold">
                  {category}
                </BaseLink>
                {idx < categories.length - 1 ? ", " : " "}
              </React.Fragment>
            ))}
          </div>
        </PostInfo>
        <Body dangerouslySetInnerHTML={{ __html: html }} />
        <OtherPosts otherCount={otherPosts.length}>
          <h6 tw="mb-5 font-semibold">What you can read next</h6>
          <OpPostsContainer>
            {otherPosts &&
              otherPosts.map(
                (
                  {
                    node: {
                      frontmatter: { title, date, hero },
                    },
                  },
                  postIdx
                ) => {
                  const pageImage = _.get(hero, "pageImage");
                  const formattedSlug = `/news/${moment(date)
                    .tz(TZ)
                    .format("YYYY-MM-DD")}-${_.kebabCase(title)}`;
                  return (
                    <OpArticle key={postIdx}>
                      <Link to={formattedSlug}>
                        {pageImage ? (
                          <GatsbyImage
                            image={getImage(pageImage)}
                            alt="Page Image"
                          />
                        ) : (
                          <LogoImgBackup
                            aspectRatio={50}
                            color={MAIN_COLOR}
                            src={BACKUP_LOGO_URL}
                          />
                        )}
                      </Link>
                      <OpHeaderLink to={formattedSlug}>
                        <span>{title}</span>
                      </OpHeaderLink>
                    </OpArticle>
                  );
                }
              )}
          </OpPostsContainer>
        </OtherPosts>
      </InnerSection>
    </Layout>
  );
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        seo {
          title
          description
        }
        title
        date
        author
        categories
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 2
                layout: FULL_WIDTH
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
          pageImagePosition
          useMobileImage
          mobileImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    otherPosts: allMarkdownRemark(
      filter: {
        frontmatter: { templateKey: { eq: "news-post" }, publish: { eq: true } }
        id: { ne: $id }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            hero {
              pageImage {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 2)
                }
              }
            }
          }
        }
      }
    }
  }
`;
